
/* --- side-menu-bx css Start --- */
.side-menu-bx{
    width: 250px;
    position: fixed;
    top: 0px;
    left: -260px;
    height: 100%;
    z-index: 111;
    background: #ffffff;
    overflow: hidden;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.side-menu-bx.active {
    left: 0;
    opacity: 1;
    visibility: visible;
}

.close-icon{
    position: absolute;
    right: 7px;
    top: 4px;
    width: 25px;
    height: 25px;
    background: transparent;
    border-radius: 100%;
    display: flex;
    color: black;
    padding: 2px;
    opacity: 0.7;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.close-icon:hover{
    transform: rotate(360deg);
    background: #d11c21;
    color: white;
    opacity: 1;
}

.smb-logo-bx {
    background: hsl(0deg 0% 94% / 80%);
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
}

.smb-logo-bx .smb-logo-link {
    display: block;
    height: 39px;
}

.smb-logo-bx .smb-logo-link img.logo-img {
    width: 125px;
    height: 50px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(20px);
    transition: 0.3s all 0.5s ease;
}

.side-menu-bx.active .smb-logo-bx .smb-logo-link img.logo-img {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
}

.smb-menu-bx {
    height: calc(100% - 100px - 137px);
    max-height: calc(100% - 100px - 137px);
    position: absolute;
    width: 100%;
    top: 0;
    margin-top: 100px;
    overflow: auto;
    background: white;
}

.smb-menu-bx ul {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0 0px;
    list-style: none;
}

.smb-menu-bx ul li {
    margin: 0px 0;
    opacity: 0;
    visibility: hidden;
    transform: translateY(20px);
    transition: 0.3s all ease-in-out;
}

.side-menu-bx.active .smb-menu-bx ul li {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
}

.side-menu-bx.active .smb-menu-bx ul li:nth-child(1) {
    transition-delay: 0.6s;
}

.side-menu-bx.active .smb-menu-bx ul li:nth-child(2) {
    transition-delay: 0.7s;
}

.side-menu-bx.active .smb-menu-bx ul li:nth-child(3) {
    transition-delay: 0.8s;
}

.side-menu-bx.active .smb-menu-bx ul li:nth-child(4) {
    transition-delay: 0.9s;
}

.side-menu-bx.active .smb-menu-bx ul li:nth-child(5) {
    transition-delay: 1.0s;
}

.side-menu-bx.active .smb-menu-bx ul li:nth-child(6) {
    transition-delay: 1.1s;
}

.side-menu-bx.active .smb-menu-bx ul li:nth-child(7) {
    transition-delay: 1.2s;
}

.side-menu-bx.active .smb-menu-bx ul li:nth-child(8) {
    transition-delay: 1.3s;
}

.smb-menu-bx ul li .smb-link {
    padding: 0px 20px 0px 15px;
    display: flex;
    text-decoration: none;
    color: #777777;
    position: relative;
    text-transform: capitalize;
    height: 45px;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    align-items: center;
    transition: all 0.3s ease-in-out;
    border-bottom: 1px solid #f5f5f5;
    border-left: 3px solid white;
    cursor: pointer;
}

.smb-menu-bx ul li:last-child .smb-link {
    border-bottom: none;
}

.smb-menu-bx ul li .smb-link ion-icon{
    margin-right: 10px;
    width: 20px;
    height: 20px;
    width: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -1px;
}

.smb-menu-bx ul li .smb-link:hover {
    color: #ce0000;
    border-left-color: #ce0000;
}

.smb-menu-bx ul li .smb-link.active {
    color: #ce0000;
    border-left-color: #ce0000;
}

.smb-footer{
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 15px 10px 10px;
    background-color: #fcfcfc;
    width: 100%;
    margin: 0;
}

.smb-footer .csl-lable:nth-child(1){
    display: none;
}